.tabBar{
    --background: #ff5e00;
    --color:#FFFF;
    --ion-color-primary: #00050d;
}

.btnSalvarCupom{   
    border: 1px solid #ff5e00;
    --background:  transparent;   
    border-radius: 10px;
    --color:#ff5e00;
    --box-shadow:0;     
}