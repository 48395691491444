 .login{
    --background:  #FF6409;   
    --color:#FFFF;
 }

.btnSuccess{
    width: 310px; 
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    --background:  transparent;
    --box-shadow:0;
}

.btnPrimary{
    width: 310px; 
    border: 1px solid #E2FF24;
    --background:  transparent;   
    border-radius: 10px;
    --color:#FFFF;
    --box-shadow:0
     
}

#form{
    
    width: 310px; 
    border-radius: 10px;
    margin: 210px auto;
     
     
}
#novoMotorista{
    width: 310px;
    padding: 5px 25px 5px 0;
    border-radius: 10px;
    margin:  auto;
}
.itemInput{
    --background:  transparent;
    --color:#FFFF;   
    --border-color: #FFF;
    --ion-color-primary:#E2FF24;
}

 
 
