.contentSorteio{
    --background:  #FF6409;   
    --color:#FFFF;
    
}

.sorteioItem{
    --background:  transparent;  
    --color:#FFFF;  
}

.imgLogoSorteio{
     margin-top: 50%;    
}

.imgLogoSorteio img{
    margin-left: 50px;
}

.inputSorteio{
    padding: 20px;
    border: 1px solid #FFF;
    border-radius: 10px; 
    padding: 10px;
    text-transform: uppercase;
    margin-top: 10px;
} 

.btnSorteio{
    
    border: 1px solid #E2FF24;
    --background:  transparent;   
    border-radius: 10px;
    --color:#FFFF;
    --box-shadow:0;  
    width: 100%;
    height: 40px;
}